$icons: (
    truck-custom: 'truck-custom.svg',
) !default;

@each $name, $value in $icons {
    .fa-#{$name} {
        width: 1.5rem;
        background-size: contain;
        height: 1.5rem;
    }
}
