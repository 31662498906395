$side: 0.4;
$dimension: $side + rem;

.child-green-round > p::before {
    content: '';
    position: absolute;
    border-radius: 100%;
    top: (1 - $side)/2 + rem;
    left: 0;
    width: $dimension;
    height: $dimension;
    background-color: green;
}
