.hide-filters {
    height: 0;
    transition: all .5s ease-in-out;
    transform-origin: top;
    transform: scaleY(0);
    border: none !important;
    line-height: 0;
}

.show-filters {
    transition: all .5s ease-in-out;
    height: 100%;
    transform-origin: top;
    transform: scaleY(1);
    line-height: 1.5;
}
