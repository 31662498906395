@import "../../theme";

.documents {
    .table {
        > table {
            > thead {
                th {
                    padding: 0.2rem;
                    line-height: 3rem;
                    &.controlButtons {
                        max-width: 52px;
                    }
                }
            }
        }
    }

}
