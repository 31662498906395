@import "../../theme";

.table {
  position: relative;
  font-family: $font-family-light;
  overflow-x: auto;
  margin-top: 3rem;

  table {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      width: 100%;
      height: 1px;
      background-color: $sc-blue-light;
      display: block;
    }
  }

  .pretty {
    margin-right: 0;
  }

  .loading {
    position: absolute;
    left: 0;
    top: -1rem;
    z-index: 1;
  }

  .DateRangePickerInput_arrow {
    right: 0;
    font-size: 1em;
  }

  .user-management__ButtonCell {
    justify-content: center;
  }

  & > table {
    width: 100%;

    & > thead {
      background-color: $dke-blue-default;

      th {
        padding: 0;

        &:first-child {
          min-width: unset;
        }

        &.familleOrganisation {
          .searchBar_select {
            width: auto;
          }
        }
      }

      .table__header-cell {
        position: relative;
        min-height: 70px;
        margin-left: 10px;
        color: $white;

        &::after {
          content: "";
          clear: both;
          display: table;
        }
      }
      .table__header-cell-no-filter {
        position: relative;
        min-height: 30px;
        color: map-get($colors, "primary");
        margin-left: 10px;
        @media screen and (max-width: 1190px) {
          min-height: 5vh;
        }
      }
    }

    & > thead th {
      position: relative;
      padding: 12px 2px 0;
      font-family: "DINPro";
      font-size: 0.96rem;
      color: $white;
      line-height: 1rem;
      vertical-align: middle;
    }

    & > tbody td {
      height: 3rem;
      vertical-align: middle;
      padding: 0;
      color: $expert-blue;
      font-size: 0.92rem;
      line-height: 1.8rem;
      border-bottom: 0.1rem solid $gray-0;
      word-break: break-word;

      &:not(:first-child) {
        padding: 5px 10px;
      }
    }

    & > tbody tr:nth-child(odd) {
      background-color: $white;
    }

    & > tbody tr:nth-child(even) {
      background-color: $gray-0;
    }

    & > thead tr:first-child th:first-child .table__header-cell .table__separation,
    & > thead tr:first-child th:first-child .table__header-cell .table__sort,
    & > thead tr:first-child th:first-child .table__header-cell-no-filter .table__separation,
    & > thead tr:first-child th:first-child .table__header-cell-no-filter .table__sort,
    & > thead .controlButtons .table__sort{
      display: none;
    }
  }

  &__sort {
    position: relative;
    top:5px;
    left: 10px;

    &-icon {
      height: 0.9rem;

      & path {
        fill: $orange;
      }

      &--inactive path {
        fill: $expert-blue;
      }
    }
  }

  [class^='sz-icon-bold'], [class*=' sz-icon-bold']{
    font-size: 1.2rem;
  }

  &__filter {
    position: absolute;
    bottom: 0;
    left: 6px;
    right: 6px;
    height: 48px;

    input {
      width: 99%;
      height: 35px;
    }

    &-button {
      border: none;
      background-color: inherit;
      outline: 0 !important;
    }

    .sz-select{
      width: auto;
      max-width: 96%;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    div[class*='-control'] ,
    .css-4vulad-control
    {
      height: 38px !important;
      min-height: 38px;
    }

    .DateRangePicker {
      line-height: 1.5;
      font-size: 1em;
      border: none;

      .DateInput {
        width: 80px;
      }

      .DateRangePickerInput_arrow svg {
        width: 16px;
        height: 16px;
      }

      input.DateInput_input {
        font-size: 1em;
        padding: 0 3px;
      }
    }

    &-input {
      height: 23px;

      &--hidden {
        display: none;
      }
    }

    &-icon {
      height: 1.3rem;
      cursor: pointer;
    }

    .searchBar_select {
      width: auto;
      max-width: 98%;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    // sds overrides
    [class$="singleValue"] {
      line-height: 20px;
    }
  }

  &__separation {
    position: absolute;
    top: -8px;
    right: 0;
    background-color: $gray-100;
    display: inline-block;
    width: 0.2rem;
    height: 3rem;
  }
  &__disableRow {
    color: $blue-4;
  }
}
