@import "../../theme";

.input-group {
    margin-bottom: 0 !important;
}

// CSS généré dans SzSelect ou une de ses dépendances :
// - 4vulad correspond au contrôle sans focus
// - 1ghgd0a correspond au contrôle avec focus
// Codes susceptibles de changer avec les versions
.css-4vulad-control, .css-1ghgd0a-control, .react-date-picker__wrapper {
    background-color: $primary-light !important;
    height: 58px;

    ::-webkit-scrollbar {
        width: 0 !important
    }

    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    & > div {
        height: 100%;
        overflow-y: scroll;
    }
}

div[class*='-control'] {
    height: 58px;

    & > div {
        height: 100%;
        overflow-y: scroll;
    }
}

#filtersBar {
    .navbar-collapse {
        align-items: stretch;

        & > div.d-flex {
            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
                width: 100%;
            }
        }

        @media screen and (min-width: map_get($grid-breakpoints, 'md')) {
            border-left: 2px solid $primary-light;
            & > div.col-filter {
                flex: .3;
                border-color: $primary-light !important;
                border-right: 2px solid $primary-light;
            }
        }
    }

    .sz-form-group {
        margin-bottom: 0px;
    }
    .sz-select-input_placeholder.form-control:read-only {
        background-color: inherit !important;
        border-top: none !important;
        border-bottom: none !important;
        border: 0 !important;
        min-height: 30px;
        font-weight: 700;
        padding-top: 0;
    }
    .sz-select-input_placeholder.form-control::placeholder {
        color: white;
    }
    .sz-select-custom__wrapper-list {
        margin-top: 15px;
        max-height: 450px;
        overflow-x: hidden;
    }
    .sz-select-custom__item {
        color: $primary;

        & .sz-checkbox {
            // sz-select-custom__item has:
            // - 56px height
            // - 2px border bottom
            // - 8px padding top and down
            // => 56 - 2 - (2*8) = 38px left for content instead of 58px set globaly
            height: 38px;
        }
    }

    .css-4vulad-control, .css-1ghgd0a-control, .css-2b097c-container {
        background-color: inherit !important;
        border-top: none;
        border-bottom: none;
        border: 0;
        min-height: 30px;
    
        & .css-1wa3eu0-placeholder, & .css-1uccc91-singleValue, & .css-1wy0on6 > div, & .css-193anet-indicatorContainer , & .css-1hb7zxy-IndicatorsContainer > div  {
            color: white!important;
        }
        & .css-1uv6pfg-menu {
            max-height: 30rem;
            overflow: auto;
        }

        & .css-1rhbuit-multiValue {
            margin: 1px;
        }

        .css-12jo7m5 {
            font-size: 78%;
            padding: 2px;
            padding-left: 5px;
        }

        .css-g1d714-ValueContainer, .css-1hwfws3 {
            padding: 2px 6px;
        }

        .css-tlfecz-indicatorContainer {
            padding: 6px;
        }
        .text-secondary {
            color: $expert-blue !important;
            font-family: $font-family-light;
            font-size: .7rem;
        }
        .sz-list_item {
            height: 44px;
        }
    }
}

.css-2b097c-container {
    color: $primary;
}

.sz-form-group__input-icon {
    z-index: 2;
}

.header-wrapper {
    flex-direction: row;
    justify-content: space-between;
}
