@import "../../theme";

.demands {
    &__ButtonCell {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 2px;

        button {
            font-family: $font-family-bold;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            margin-left: 2px;
        }
    }
    &__statusCell {
        width: 120px;
    }

    &__MaterialsCell {
        display: flex;
        flex-direction: column;
    }
    .id {
        max-width: 4.6rem;
    }

    .table {
        > table {
            > thead {
                th {
                    &.selection,
                    &.id,
                    &.containerName,
                    &.status,
                    &.companyName,
                    &.materials,
                    &.controlButtons {
                        .table__sort {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.rc-time-picker {
    display: block !important;
}

.rc-time-picker-input,
.rc-time-picker-panel-input-wrap,
.rc-time-picker-panel-input {
    padding: 4px 12px !important;
    height: 58px !important;
    color: $expert-blue !important;
    font-size: 1.1rem !important;
    font-weight: 900;
    margin-left: 4px;
}

.rc-time-picker-panel {
    width: 100% !important;
}

.rc-time-picker-panel-narrow {
    max-width: 180px !important;
}
.rc-time-picker-panel-select {
    width: 89px !important;
    max-height: 198px !important;
    font-size: 14px !important;
}

.rc-time-picker-clear {
    display: none;
}
