#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.bg-suez-login {
    flex: 1 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-transparency-login {
    background-color: rgba(8, 8, 81, 0.74);
}
