@import "../../../theme";

.legend {
    @for $i from 1 through 10 {
        &.fs-#{$i}:before {
            height: (0.25 * $i) + rem;
            width: (0.25 * $i) + rem;
        }
    }

    &:before {
        height: 0.5rem;
        content: ' ';
        width: 0.5rem;
        display: block;
        margin: auto 0.25rem auto 0
    }

    &.service:before {
        background-color: $secondary;
    }

    &.valorisation:before {
        background-color: $secondary-light;
    }
}
