input.bg-light {
    background-color: #eaedf4 !important;
}

.bg-suez-login {
    .grecaptcha-badge{
        z-index: 1;
        bottom: 3.5rem !important;
    }
}
