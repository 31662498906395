@import '../../../theme';

$tooltip-size: 0.5 + rem;
$background: #030f40;

.sz-modal {
    color: $primary !important;
}

.tooltip-inner {
    background-color: $background;
}

div[class*='bs-tooltip-']::after {
    content: '';
    position: absolute;
    width: $tooltip-size + rem;
    height: $tooltip-size + rem;
}

.bs-tooltip-top::after {
    border-top: $tooltip-size solid $background;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    border-left: $tooltip-size solid transparent;
    border-right: $tooltip-size solid transparent;
}

.bs-tooltip-left::after {
    border-left: $tooltip-size solid $background;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
    border-top: $tooltip-size solid transparent;
    border-bottom: $tooltip-size solid transparent;
}

.tooltip {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    transform: translate(-50%, calc(-100% - #{$tooltip-size}));
    font-style: normal;
    padding: 6px;
    pointer-events: none;
    z-index: 2 !important;
}

.tooltip:not(div[class*=bs-tooltip])::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: $tooltip-size + rem;
    height: $tooltip-size + rem;
    border-left: $tooltip-size solid transparent;
    border-right: $tooltip-size solid transparent;
    border-top: $tooltip-size solid $background;
}

#chartjs-tooltip.tooltip {
    border: 1px solid $background;
    &:after {
        border-top: $tooltip-size solid white;
    }
}

.clamped {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.chart-container {
  position: relative;
  min-height: 40vh;
}
