@import "../../theme";

.chip {
    display: inline-flex;
    flex-direction: row;
    background-color: $gray-200;
    color: black;
    border: none;
    cursor: default;
    height: 30px;
    outline: none;
    padding: 4px;
    font-size: 12px;
    margin: 2px 1px;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
}

.chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}
