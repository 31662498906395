@import "../../theme";

.table-pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  border-top: 0.1rem solid $gray-0;
  font-size: 1.2rem;
  line-height: 1.8rem;

  &__top-part {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div {
    & button {
      width: 2.3rem;
      height: 2.3rem;
      margin: 0 0.2rem;
      padding: 7px 0 8px;
      border: 0.1rem solid $brand-light;
      border-radius: 50%;
      background-color: inherit;
      font-family: $font-family-regular;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1rem;
      outline: 0;

      &:enabled:hover {
        border-color: $tm-blue-default;
        background-color: transparent;
      }

      &:disabled {
        border-color: $blue-clear-light;
        color: $expert-blue;
        cursor: not-allowed;
      }
    }
  }

  &__count {
    margin: 0 1rem;
    font-size: 1.2rem;
  }

  &__line-per-page {
    margin-top: 1.2rem;
  }

  &__select {
    padding: 0.3rem;
  }
}
