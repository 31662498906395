@import './theme';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import './icons';
@import '~react-theme-components/assets/scss/2-tools/2-tools.scss';
@import '~react-theme-components/assets/scss/3-generic/3-generic.scss';
@import '~react-theme-components/assets/scss/4-elements/4-elements.scss';
@import '~react-theme-components/assets/scss/5-objects/5-objects.scss';
@import '~react-theme-components/assets/scss/6-components/6-components.scss';
@import '~react-theme-components/assets/scss/7-utilities/7-utilities.scss';

$directions: (
    left,
    right,
    top,
    bottom,
) !default;

@mixin size-x {
    @for $i from 1 through 10 {
        .size-#{$i} {
            font-size: (1/4)*$i + rem !important;
        }
        .fs-#{$i}-em {
            font-size: .1 * $i + em;
        }
    }
}

.pointer {
    cursor: pointer;
}

.transition {
    transition: all 0.5s;
}

@for $i from 1 through 5 {
    .w-#{$i}-rem {
        width: $i + rem !important;
    }
    .h-#{$i}-rem {
        height: $i + rem !important;
    }
    @each $breakpoint, $value in $grid-breakpoints {
        @media screen and (min-width: $value){
            .fs-#{$breakpoint}-#{$i} {
                font-size: (.5 * $i) + rem;
            }
        }
    }
    .fs-#{$i} {
        font-size: (.5 * $i) + rem;
    }
    .z-#{$i} {
        z-index: $i;
    }
}

@for $i from 1 through 4 {
    .w-#{$i}-rem {
        width: $i + rem !important;
    }
    .h-#{$i}-rem {
        height: $i + rem !important;
    }
    .fs-#{$i} {
        font-size: (.5 * $i) + rem;
    }
    .rotate-#{$i} {
        transform: rotate((180 * ($i / 4)) + deg);
    }
}

@each $direction in $directions {
    .#{$direction}-0 {
        #{$direction}: 0;
    }
}

@each $name, $width in $grid-breakpoints {
    @media (min-width: $width) {
        @each $direction in $directions {
            .border-#{$name}-#{$direction} {
                border-#{$direction}: 2px solid $border;
            }
            .border-#{$name}-#{$direction}-none {
                border-#{$direction}: none;
            }
            .border-#{$name} {
                border: 2px solid $border;
            }
            .border-#{$name}-none {
                border: none;
            }
        }
    }
}

@each $name, $property in $colors-application {
    @each $value, $p in $colors-palette {
        .#{$name}-#{$value} {
            #{$property}: $p;
        }
    }
}

.mh-50-vh {
    min-height: 50vh;
}

.flex-1 {
    flex: 1;
}

.font-family-bold {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
    font-weight: unset !important;
}

.fs-inherit {
    font-size: inherit;
}

.sz-modal_header h2 {
    text-transform: uppercase;
    position: relative;
    padding-bottom: 5px;
}
.title-underline::after, .sz-modal_header h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $secondary;
    height: 4px;
    width: 4rem;
}

body {
    background-color: $background !important;
    color: $primary !important;
    font-family: "DINPro-Regular";
    font-weight: bold;
}

@include size-x;

// Passage SDS V1 to V2
.sz-btn {
    width: 100%;
    &-tertiary-icon {
        width: 3rem!important;
    }
}
.sz-form-group__label.required:after {
    content: '*';
    position: absolute;
    color: var(--danger);
    right: 0;
}

.cookies-card a {
    color: $brand-light;
}
